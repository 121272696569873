import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EmployeeDto } from 'src/app/shared/models/employee/employee-dto.model';
import {
  BaseFormBinderService,
  nullReplacer
} from 'src/app/shared/services/form/base-form-binder.service';
import { ValidationExtension } from 'src/app/shared/validation/validation-extensions.const';
import { WorkshopCardNumberFormValidation } from './workshop-card-number.model';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';
import { ValidationService } from 'src/app/shared/validation/validation.service';

@Injectable()
export class WorkshopCardNumberFormBinder extends BaseFormBinderService {
  constructor(private formBuilder: UntypedFormBuilder, private validationService: ValidationService) {
    super();
  }
  protected createForm(fieldValidations: WorkshopCardNumberFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator, ValidationFunctions.cardNumberValidator(this.validationService)));
    });
    return fg;
  }

  addRequiredValidatorToWorkshopCardNumber(
    validation: WorkshopCardNumberFormValidation
  ): WorkshopCardNumberFormValidation {
    return {
      formGroupName: validation.formGroupName,
      formFields: {
        workshopCardNumber: {
          validators: Validators.compose([
            validation.formFields.workshopCardNumber,
            Validators.required
          ]),
          asyncValidators: Validators.composeAsync([ValidationFunctions.cardNumberValidator(this.validationService)])
        } as any
      }
    };
  }
}
