<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <h3 class="ic-page__form-title">
      {{ staticContent.workshopCardTitle }}
    </h3>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-form-input inputId="workshopCardNumber" formControlName="workshopCardNumber"
          [label]="staticContent?.workshopCardNumber.label" (blured)="onBlur()" maxLength="16"
          [placeholder]="staticContent?.workshopCardNumber.placeholder" [formGroup]="formGroup"
          [isRequired]="isControlRequired('workshopCardNumber')" [warnValue]="findPreviousValue('technicianCardNumber')"
          [isReportedForCorrection]="isFieldIncludedInCorrection('technicianCardNumber')" [errorList]="[
            {
              label: staticContent?.workshopCardNumber.error,
              type: ['minlength', 'maxlength']
            },
            {
              label: staticContent?.workshopCardNumber.pattern,
              type: ['pattern']
            },
            {
              label: staticContent?.workshopCardNumber.error2,
              type: ['required']
            }
          ]">
        </app-form-input>
      </div>
    </div>
  </div>
</div>