import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { STC_V1 } from '../consts/api-version.config';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private http: HttpClient) { }

  checkAllowedChars(val: string): Observable<{ isValid: boolean }> {
    const url = `${environment.BACKEND_API.BASE}/validation/is-valid-character`;
    const body = {
      value: val
    }
    const header = new HttpHeaders().set('Content-Type', STC_V1);

    return this.http.put<{ isValid: boolean }>(url, body, { headers: header });
  }

  cardNumberValidator(val: string): Observable<{ isValid: boolean }> {
    const url = `${environment.BACKEND_API.BASE}/validation/is-card-number-valid`;
    const body = {
      value: val
    }
    const header = new HttpHeaders().set('Content-Type', STC_V1);

    return this.http.put<{ isValid: boolean }>(url, body, { headers: header });
  }
}
