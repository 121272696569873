import { MY_ACCOUNT_ROUTE } from 'src/app/my-account/_consts/my-account-route.const';
import { PARTNERS_ROUTE } from 'src/app/service-category/services-info-pages/tachograph/partners/_consts/partners-route.const';
import {
  DRIVER_LICENSE_ROUTE,
  VEHICLE_REGISTRATION_ROUTE,
  TACHOGRAPH_ROUTE,
  ADR_ROUTE
} from 'src/app/service-category/_consts/service-category-route.const';
import {
  SITE_MAP_PAGE,
  CONTACTPAGE_ROUTE,
  ABOUT_US,
  TERMS_OF_SERVICE_ROUTE
} from 'src/app/info-pages/_consts/info-pages-route.const';
import { Footer } from '../_models/footer.model';
import { REGISTRATION_ROUTE } from 'src/app/registration/_consts/registration-routes.const';
import { LOGIN_ROUTE } from 'src/app/login/_consts/login-route.const';

export const FOOTER_LINKS: Footer = {
  top: {
    facebook: { href: 'https://www.facebook.com/infocarpl/' },
    twitter: { href: 'https://twitter.com/pwpw_sa' }
  },
  middle: {
    firstColumn: {
      about: { routerLink: ABOUT_US },
      regulations: { routerLink: TERMS_OF_SERVICE_ROUTE },
      privacyPolicy: { routerLink: TERMS_OF_SERVICE_ROUTE, fragment: '5' },
      siteMap: { routerLink: SITE_MAP_PAGE }
    },
    secondColumn: {
      driverLicense: { routerLink: '/' + DRIVER_LICENSE_ROUTE },
      vehicleRegistration: { routerLink: '/' + VEHICLE_REGISTRATION_ROUTE },
      tachograph: { routerLink: '/' + TACHOGRAPH_ROUTE },
      adr: { routerLink: '/' + ADR_ROUTE }
    },
    thirdColumn: {
      partners: { routerLink: '/' + TACHOGRAPH_ROUTE + '/' + PARTNERS_ROUTE },
      myAccount: { routerLink: MY_ACCOUNT_ROUTE },
      login: { routerLink: LOGIN_ROUTE },
      register: { routerLink: REGISTRATION_ROUTE },
      contact: { routerLink: CONTACTPAGE_ROUTE }
    },
    tachographColumn: {
      tachograph_phone: { href: 'tel:+48223329290' },
      tachograph_email: { href: 'mailto:tachograf@pwpw.pl' },
      tachograph: {}
    },
    otherColumn: {
      other_phone: { href: 'tel:+48224647999' },
      other_email: { href: 'mailto:infocar@pwpw.pl' },
      other: {}
    }
  },
  bottom: {
    edoApp: { href: 'https://www.edoapp.pl/' },
    edoPost: { href: 'https://edopost.pl/' },
    sigillum: { href: 'https://sigillum.pl/' },
    exciseBand: { href: 'https://www.banderolaakcyzowa.pl/#/sprawdz' },
    peopleAndDocs: { href: 'https://www.pwpw.pl/Czlowiek_i_dokumenty' },
  }
};
