<div class="ic-page" [formGroup]="formGroup" [attr.id]="htmlElementId ? htmlElementId : null">
  <div class="ic-page__form">
    <h3 class="ic-page__form-title">
      {{ staticContent.companyCardTitle }}
    </h3>
    <div class="row">
      <div class="col-12 col-md-6">
        <app-form-input inputId="companyCardNumber" formControlName="companyCardNumber"
          [label]="staticContent?.companyCardNumber.label" (blured)="onBlur()"
          [placeholder]="staticContent?.companyCardNumber.placeholder" maxLength="16" [formGroup]="formGroup"
          [isRequired]="isControlRequired('companyCardNumber')"
          [isReportedForCorrection]="isFieldIncludedInCorrection('companyCardNumber')"
          [warnValue]="findPreviousValue('companyCardNumber')" [errorList]="[
            {
              label: staticContent?.companyCardNumber.error,
              type: ['minlength', 'maxlength']
            },
            {
              label: staticContent?.companyCardNumber.pattern,
              type: ['pattern']
            },
            {
              label: staticContent?.companyCardNumber.error2,
              type: ['required']
            }
          ]">
        </app-form-input>
      </div>
    </div>
  </div>
</div>