<div [@loaderAnimation]="loaders.animation" (@loaderAnimation.start)="onAnimationStart($event)"
  (@loaderAnimation.done)="onAnimationEnd($event)" *ngIf="loaders.show && loaders.type === 'splash'"
  class="overlay splash" [style.position]="loaders.fullScreen ? 'fixed' : 'absolute'">
  <div class="logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="148.275" height="38.525" viewBox="0 0 148.275 38.525">
      <g id="logo_infocar_desktop" transform="translate(-527 -200)">
        <g id="Group_2174" data-name="Group 2174" transform="translate(337.806 -58.971)">
          <g id="Group_982" data-name="Group 982" transform="translate(189.194 258.971)">
            <path id="Path_1885" data-name="Path 1885"
              d="M192.34,264.787h0a3.145,3.145,0,1,0,0,6.291h0a3.145,3.145,0,0,0,0-6.291Z"
              transform="translate(-189.194 -260.213)" fill="#38c78b" />
            <rect id="Rectangle_260" data-name="Rectangle 260" width="6.291" height="23.303"
              transform="translate(0 15.222)" fill="#fff" />
            <path id="Path_1886" data-name="Path 1886"
              d="M213.486,278.327h-9.292v23.3h6.291V284.618h3a5.366,5.366,0,0,1,5.36,5.36h0V301.63h6.291V289.979A11.651,11.651,0,0,0,213.486,278.327Z"
              transform="translate(-192.397 -263.105)" fill="#fff" />
            <path id="Path_1887" data-name="Path 1887"
              d="M347.313,278.327h-6.291v1.313a11.651,11.651,0,1,0,0,20.677v1.313h6.291v-23.3Zm-11.651,17.011a5.36,5.36,0,1,1,5.36-5.36A5.365,5.365,0,0,1,335.662,295.338Z"
              transform="translate(-217.986 -263.105)" fill="#fff" />
            <path id="Path_1888" data-name="Path 1888"
              d="M261.932,278.327a11.651,11.651,0,1,0,11.651,11.652A11.651,11.651,0,0,0,261.932,278.327Zm0,17.011a5.36,5.36,0,1,1,5.36-5.36A5.366,5.366,0,0,1,261.932,295.338Z"
              transform="translate(-202.24 -263.105)" fill="#fff" />
            <path id="Path_1889" data-name="Path 1889"
              d="M308.97,295.338a5.36,5.36,0,1,1,3.585-9.333l4.45-4.45a11.651,11.651,0,1,0,0,16.847l-4.45-4.45A5.332,5.332,0,0,1,308.97,295.338Z"
              transform="translate(-212.286 -263.105)" fill="#fff" />
            <path id="Path_1890" data-name="Path 1890"
              d="M242.571,272.862h0v-2.24h0a5.366,5.366,0,0,1,5.36-5.36h2.214v-6.291h-2.214a11.651,11.651,0,0,0-11.652,11.651V280.7h0v16.8h6.291V280.7h3.642V274.41h-3.642Z"
              transform="translate(-199.25 -258.971)" fill="#fff" />
            <path id="Path_1891" data-name="Path 1891"
              d="M365.485,278.327h-6.291v23.3h6.291V284.618a5.341,5.341,0,0,1,3.84,1.629l4.45-4.45A11.61,11.61,0,0,0,365.485,278.327Z"
              transform="translate(-225.5 -263.105)" fill="#fff" />
          </g>
          <path id="Path_1892" data-name="Path 1892"
            d="M287.61,289.143h0a3.145,3.145,0,0,0,0,6.291h0a3.145,3.145,0,1,0,0-6.291Z"
            transform="translate(-20.347 -6.444)" fill="#fff" />
        </g>
      </g>
    </svg>
  </div>
  <div class="car-loader">
    <svg class="reversed" x="0px" y="0px" viewBox="0 0 200 210">
      <g>
        <g id="svg_1">
          <path class="tree" id="svg_2"
            d="m164.8469,124.55752l20.1,0m-10.1,-44c0,0.4 0,42.9 0,42.9m-4.8,-35.7l4.6,3.3m5.1,-8.1l-4.6,3.3m7.5,10.7c6.5,-3 11,-9.5 11,-17.1c0,-10.4 -8.4,-18.8 -18.8,-18.8c-10.4,0 -18.8,8.4 -18.8,18.8s8.4,18.8 18.8,18.8" />
          <path class="tree tree-2"
            d="m164.8469,124.55752l20.1,0m-10.1,-44c0,0.4 0,42.9 0,42.9m-4.8,-35.7l4.6,3.3m5.1,-8.1l-4.6,3.3m7.5,10.7c6.5,-3 11,-9.5 11,-17.1c0,-10.4 -8.4,-18.8 -18.8,-18.8c-10.4,0 -18.8,8.4 -18.8,18.8s8.4,18.8 18.8,18.8" />
        </g>
        <path class="fill car car__body" id="svg_3"
          d="m138.9469,128.35752c3.8,0 6.8,-3.1 6.8,-6.8l0,-3.6c0,-3.8 -3.1,-6.8 -6.8,-6.8l-3.6,0l-10.6,0l-16.2,-16l-31.8,0l-8.6,12.5l-6.7,0c-2.7,0 -4.9,2.2 -4.9,4.9l0,10.7c0,2.7 2.2,4.9 4.9,4.9l77.5,0l0,0.2z" />
        <path class="stroke car car__body car__body--stroke" id="svg_18"
          d="m81.4469,128.35752l37.7,0m-42.1,-23.3l-3.5,7.9l15.1,0m27,-0.1l-18.1,0l0,-7.9m-33.1,23.4l-3,0c-2.7,0 -4.9,-2.2 -4.9,-4.9l0,-10.7c0,-2.7 2.2,-4.9 4.9,-4.9l6.7,0l8.6,-12.5l31.9,0l16.1,15.9l10.6,0l3.6,0c3.8,0 6.8,3.1 6.8,6.8l0,3.6c0,3.8 -3.1,6.8 -6.8,6.8l-3.6,0" />
        <g id="svg_4">
          <circle class="fill wheel wheel--left" id="svg_5" r="6.3" cy="129.15752" cx="72.2469" />
        </g>
        <g id="svg_6">
          <circle class="fill wheel wheel--right" id="svg_7" r="6.3" cy="129.15752" cx="127.5469" />
        </g>
        <g id="svg_8">
          <path class="stroke wheel wheel--left" id="svg_9"
            d="m72.2469,122.65752c3.6,0 6.5,2.9 6.5,6.5s-2.9,6.5 -6.5,6.5s-6.5,-2.9 -6.5,-6.5s2.9,-6.5 6.5,-6.5m0,-2.5c-4.9,0 -9,4 -9,9c0,4.9 4,9 9,9s9,-4 9,-9c-0.1,-5 -4.1,-9 -9,-9l0,0z" />
        </g>
        <g id="svg_10">
          <path class="stroke wheel wheel--right" id="svg_11"
            d="m127.5469,122.65752c3.6,0 6.5,2.9 6.5,6.5s-2.9,6.5 -6.5,6.5s-6.5,-2.9 -6.5,-6.5s3,-6.5 6.5,-6.5m0,-2.5c-4.9,0 -9,4 -9,9c0,4.9 4,9 9,9s9,-4 9,-9c0,-5 -4,-9 -9,-9l0,0z" />
        </g>
        <g id="svg_12">
          <line class="car__line car__line--top" id="svg_13" y2="110.95752" x2="34.2469" y1="110.95752" x1="46.1469" />
        </g>
        <g id="svg_14">
          <line class="car__line car__line--middle" id="svg_15" y2="117.75752" x2="30.2469" y1="117.75752"
            x1="46.1469" />
        </g>
        <g id="svg_16">
          <line class="car__line car__line--bottom" id="svg_17" y2="124.55752" x2="37.2469" y1="124.55752"
            x1="46.1469" />
        </g>
      </g>
    </svg>
    @if(loadingText) {
    <p class="loader-text" [innerHTML]="loadingText"></p>
    }
  </div>
</div>

<div [class]="loaders.type" *ngIf="loaders.show && loaders.type === 'spinner'" [@loaderAnimation]="loaders.animation"
  [style.background-color]="loaders.bdColor" [style.z-index]="loaders.zIndex" [style.position]="
    loaders.fullScreen ? 'fixed' : loaders.positionRelative ? 'relative' : 'absolute'
  ">
  <div class="spinning-dot"></div>
</div>

<div [class]="loaders.type" [ngClass]="{ 'transparent-overlay': loaders.fullScreen }" *ngIf="
    loaders.show &&
    (loaders.type === 'dots' || loaders.type === 'blue-dots' || loaders.type === 'bordered-dots')
  " [@loaderAnimation]="loaders.animation" (@loaderAnimation.start)="onAnimationStart($event)"
  (@loaderAnimation.done)="onAnimationEnd($event)">
  <h2>
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="4" fill="#3d3c4a" />
    </svg>
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="4" fill="#3d3c4a" />
    </svg>
    <svg height="10" width="10">
      <circle cx="5" cy="5" r="4" fill="#3d3c4a" />
    </svg>
  </h2>
</div>

<div class="car-loader" [ngClass]="{ 'transparent-overlay': loaders.fullScreen }"
  *ngIf="loaders.show && loaders.type === 'car'" [@loaderAnimation]="loaders.animation"
  (@loaderAnimation.start)="onAnimationStart($event)" (@loaderAnimation.done)="onAnimationEnd($event)">
  <svg [ngClass]="{ boxed: loaders.fullScreen, reversed: loaders.reverse }" x="0px" y="0px" viewBox="0 0 200 210">
    <g>
      <g id="svg_1">
        <path class="tree" id="svg_2"
          d="m164.8469,124.55752l20.1,0m-10.1,-44c0,0.4 0,42.9 0,42.9m-4.8,-35.7l4.6,3.3m5.1,-8.1l-4.6,3.3m7.5,10.7c6.5,-3 11,-9.5 11,-17.1c0,-10.4 -8.4,-18.8 -18.8,-18.8c-10.4,0 -18.8,8.4 -18.8,18.8s8.4,18.8 18.8,18.8" />
        <path class="tree tree-2"
          d="m164.8469,124.55752l20.1,0m-10.1,-44c0,0.4 0,42.9 0,42.9m-4.8,-35.7l4.6,3.3m5.1,-8.1l-4.6,3.3m7.5,10.7c6.5,-3 11,-9.5 11,-17.1c0,-10.4 -8.4,-18.8 -18.8,-18.8c-10.4,0 -18.8,8.4 -18.8,18.8s8.4,18.8 18.8,18.8" />
      </g>
      <path class="fill car car__body" id="svg_3"
        d="m138.9469,128.35752c3.8,0 6.8,-3.1 6.8,-6.8l0,-3.6c0,-3.8 -3.1,-6.8 -6.8,-6.8l-3.6,0l-10.6,0l-16.2,-16l-31.8,0l-8.6,12.5l-6.7,0c-2.7,0 -4.9,2.2 -4.9,4.9l0,10.7c0,2.7 2.2,4.9 4.9,4.9l77.5,0l0,0.2z" />
      <path class="stroke car car__body car__body--stroke" id="svg_18"
        d="m81.4469,128.35752l37.7,0m-42.1,-23.3l-3.5,7.9l15.1,0m27,-0.1l-18.1,0l0,-7.9m-33.1,23.4l-3,0c-2.7,0 -4.9,-2.2 -4.9,-4.9l0,-10.7c0,-2.7 2.2,-4.9 4.9,-4.9l6.7,0l8.6,-12.5l31.9,0l16.1,15.9l10.6,0l3.6,0c3.8,0 6.8,3.1 6.8,6.8l0,3.6c0,3.8 -3.1,6.8 -6.8,6.8l-3.6,0" />
      <g id="svg_4">
        <circle class="fill wheel wheel--left" id="svg_5" r="6.3" cy="129.15752" cx="72.2469" />
      </g>
      <g id="svg_6">
        <circle class="fill wheel wheel--right" id="svg_7" r="6.3" cy="129.15752" cx="127.5469" />
      </g>
      <g id="svg_8">
        <path class="stroke wheel wheel--left" id="svg_9"
          d="m72.2469,122.65752c3.6,0 6.5,2.9 6.5,6.5s-2.9,6.5 -6.5,6.5s-6.5,-2.9 -6.5,-6.5s2.9,-6.5 6.5,-6.5m0,-2.5c-4.9,0 -9,4 -9,9c0,4.9 4,9 9,9s9,-4 9,-9c-0.1,-5 -4.1,-9 -9,-9l0,0z" />
      </g>
      <g id="svg_10">
        <path class="stroke wheel wheel--right" id="svg_11"
          d="m127.5469,122.65752c3.6,0 6.5,2.9 6.5,6.5s-2.9,6.5 -6.5,6.5s-6.5,-2.9 -6.5,-6.5s3,-6.5 6.5,-6.5m0,-2.5c-4.9,0 -9,4 -9,9c0,4.9 4,9 9,9s9,-4 9,-9c0,-5 -4,-9 -9,-9l0,0z" />
      </g>
      <g id="svg_12">
        <line class="car__line car__line--top" id="svg_13" y2="110.95752" x2="34.2469" y1="110.95752" x1="46.1469" />
      </g>
      <g id="svg_14">
        <line class="car__line car__line--middle" id="svg_15" y2="117.75752" x2="30.2469" y1="117.75752" x1="46.1469" />
      </g>
      <g id="svg_16">
        <line class="car__line car__line--bottom" id="svg_17" y2="124.55752" x2="37.2469" y1="124.55752" x1="46.1469" />
      </g>
      <g *ngIf="loadingText">
        <text class="text" x="50%" y="175" text-anchor="middle">
          {{ loadingText }}
        </text>
      </g>
    </g>
  </svg>
</div>