import { ValidationService } from './../../../validation/validation.service';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EmployeeDto } from 'src/app/shared/models/employee/employee-dto.model';
import {
  BaseFormBinderService,
  nullReplacer
} from 'src/app/shared/services/form/base-form-binder.service';
import { ValidationExtension } from 'src/app/shared/validation/validation-extensions.const';
import { DriverCardNumberFormValidation } from './driver-card-number.model';
import { ValidationFunctions } from 'src/app/shared/validation/validation-functions.const';

@Injectable()
export class DriverCardNumberFormBinder extends BaseFormBinderService {
  constructor(private formBuilder: UntypedFormBuilder, private validationService: ValidationService) {
    super();
  }
  protected createForm(fieldValidations: DriverCardNumberFormValidation) {
    const fg = new UntypedFormGroup({});
    const formFields = Object.entries(fieldValidations.formFields);
    formFields.forEach(([name, validator]: [string, ValidatorFn]) => {
      fg.addControl(name, new UntypedFormControl(null, validator, [ValidationFunctions.cardNumberValidator(this.validationService)]));
    });
    return fg;
  }

  public addRequiredValidatorToDriverCardNumber(
    validation: DriverCardNumberFormValidation
  ): DriverCardNumberFormValidation {
    return {
      formGroupName: validation.formGroupName,
      formFields: {
        driverCardNumber: {
          validators: Validators.compose([
            validation.formFields.driverCardNumber,
            Validators.required
          ]),
          asyncValidators: Validators.composeAsync([ValidationFunctions.cardNumberValidator(this.validationService)])
        } as any
      }
    };
  }
}
