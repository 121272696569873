import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, of, switchMap, take } from 'rxjs';
import {
  dateFromZeroYear,
  Extensions,
  isNullOrUndefined,
  sanitizePhoneNumber
} from 'src/app/shared/extensions/extensions';
import { ValidationService } from './validation.service';

export class ValidationFunctions {
  static allowedCharsValidator(validationService: ValidationService): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return control?.valueChanges?.pipe(
        debounceTime(600),
        distinctUntilChanged(),
        take(1),
        switchMap((el) => {
          if (!el) {
            return of(null);
          }

          return validationService.checkAllowedChars(el).pipe(
            take(1),
            map(isValid => {
              return isValid.isValid ? null : { pattern: true };
            })
          );
        })
      )

    }
  }

  static cardNumberValidator(validationService: ValidationService): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return control?.valueChanges?.pipe(
        debounceTime(600),
        distinctUntilChanged(),
        take(1),
        switchMap((el) => {
          if (!el) {
            return of(null);
          }

          return validationService.cardNumberValidator(el).pipe(
            take(1),
            map(isValid => {
              return isValid.isValid ? null : { pattern: true };
            })
          );
        })
      )

    }
  }

  static passwordMatchValidator(group: UntypedFormGroup) {
    const password: string = group.get('password').value;
    const confirmPassword: string = group.get('confirmPassword').value;
    if (password && confirmPassword)
      if (password !== confirmPassword) {
        group.get('confirmPassword').setErrors({ NoPassswordMatch: true });
      } else {
        group.get('confirmPassword').setErrors(null);
      }
  }

  static areControlsEqualValidator(
    firstControlName: string,
    secondControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.root.get(firstControlName).value !== control.root.get(secondControlName).value) {
        return { controlsNotEqual: true };
      }
      return null;
    };
  }

  static isControlEqualValidator(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== control.root.get(otherControlName).value) {
        return { controlIsNotEqual: true };
      }
      return null;
    };
  }

  static isDateAfterDateControlValidatorRoot(dateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.root.get(dateControl) && control.root.get(dateControl).value) {
        if (
          !this.isDateAfter(
            control.value.split('.').reverse(),
            control.root.get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateBeforeOtherDate: true };
        }
      }
      return null;
    };
  }

  static isDateAfterDateControlValidatorParent(dateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.parent.get(dateControl) &&
        control.parent.get(dateControl).value
      ) {
        if (
          !this.isDateAfter(
            control.value.split('.').reverse(),
            control.parent.get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateBeforeOtherDate: true };
        }
      }
      return null;
    };
  }

  static isDateAfterDateControlValidatorInGroup(
    dateControl: string,
    formGroupName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.root.get(formGroupName) &&
        control.root.get(formGroupName).get(dateControl) &&
        control.root.get(formGroupName).get(dateControl).value
      ) {
        if (
          !this.isDateAfter(
            control.value.split('.').reverse(),
            control.root.get(formGroupName).get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateBeforeOtherDate: true };
        }
      }
      return null;
    };
  }

  static isDateBeforeDateControlValidatorRoot(dateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.root.get(dateControl) && control.root.get(dateControl).value) {
        if (
          this.isDateAfter(
            control.value.split('.').reverse(),
            control.root.get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateAfterOtherDate: true };
        }
      }
      return null;
    };
  }

  static isDateBeforeDateControlValidatorParent(dateControl: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.parent.get(dateControl) &&
        control.parent.get(dateControl).value
      ) {
        if (
          this.isDateAfter(
            control.value.split('.').reverse(),
            control.parent.get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateAfterOtherDate: true };
        }
      }
      return null;
    };
  }

  static isDateBeforeDateControlValidatorInGroup(
    dateControl: string,
    formGroupName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.value &&
        control.root.get(formGroupName) &&
        control.root.get(formGroupName).get(dateControl) &&
        control.root.get(formGroupName).get(dateControl).value
      ) {
        if (
          this.isDateAfter(
            control.value.split('.').reverse(),
            control.root.get(formGroupName).get(dateControl).value.split('.').reverse()
          )
        ) {
          return { dateAfterOtherDate: true };
        }
      }
      return null;
    };
  }

  static fireValidationRoot(updateTo: string): ValidatorFn {
    return (control: AbstractControl): null => {
      if (
        control &&
        control.root &&
        control.root.get(updateTo) &&
        control.root.get(updateTo).value &&
        control.value
      ) {
        control.root.get(updateTo).updateValueAndValidity();
      }
      return null;
    };
  }

  static fireValidationParent(updateTo: string): ValidatorFn {
    return (control: AbstractControl): null => {
      if (
        control &&
        control.parent &&
        control.parent.get(updateTo) &&
        control.parent.get(updateTo).value &&
        control.value
      ) {
        control.parent.get(updateTo).updateValueAndValidity();
      }
      return null;
    };
  }

  static fireValidationInGroup(updateTo: string, formGroupName: string): ValidatorFn {
    return (control: AbstractControl): null => {
      if (
        control &&
        control.root &&
        control.root.get(formGroupName) &&
        control.root.get(formGroupName).get(updateTo) &&
        control.root.get(formGroupName).get(updateTo).value &&
        control.value
      ) {
        control.root.get(formGroupName).get(updateTo).updateValueAndValidity();
      }
      return null;
    };
  }

  static noFutureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const date = control.value.split('.').reverse();
        const from = dateFromZeroYear(date[0], date[1], date[2]).getTime();
        const now = new Date(Date.now()).getTime();
        return from >= now ? { dateInFuture: true } : null;
      }
      return null;
    };
  }

  static noPastDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const date = control.value.split('.').reverse();
        const from = dateFromZeroYear(date[0], date[1], date[2]).getTime();
        const now = new Date(2020, 2, 1).getTime();
        return from < now &&
          new Date(date.join('.')).toDateString() !== new Date(Date.now()).toDateString()
          ? { dateInPast: true }
          : null;
      }
      return null;
    };
  }

  static peselAndBirthDateValidation(
    birthDateControlName: string,
    peselControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDateControl = control.root.get(birthDateControlName);
      const peselControl = control.root.get(peselControlName);
      if (birthDateControl && birthDateControl.value && peselControl && peselControl.value) {
        let pesel = peselControl.value.substring(0, 6) as string;
        pesel =
          pesel[2] < '2'
            ? pesel
            : pesel.slice(0, 2) + (Number.parseInt(pesel.slice(2, 3), 10) - 2) + pesel.slice(3);
        return pesel !==
          (birthDateControl.value.split('.').reverse().join('').substring(2) as string)
          ? { peselAndBirthDateNotSame: true }
          : null;
      }
      return null;
    };
  }

  static valueInArrayValidator(array: Array<any>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!array) {
        return null;
      }
      return array.includes(control.value) ? null : { valueNotInArray: true };
    };
  }
  static valueEmptyOrInArrayValidator(array: Array<any>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!array) {
        return null;
      }
      return !control.value || array.includes(control.value) ? null : { valueNotInArray: true };
    };
  }

  static objectInArrayValidator(array: Array<any>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!array) {
        return null;
      }
      return array.find((item) => Extensions.simpleObjectsEqual(item, control.value))
        ? null
        : { valueNotInArray: true };
    };
  }

  static maxLengthValidator(maxlength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        return control.value.length > maxlength ? { maxlength: true } : null;
      }
      return null;
    };
  }

  static isValueTooLong(value: string, maxlength: number): boolean {
    if (value) {
      return value.length > maxlength;
    }
    return false;
  }

  // static characterSensitiveMaxLengthValidator(maxlength: number): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     if (control.value) {
  //       const getSpecialCharacters = String(control.value).match(ValidationPatterns.characterSensitivePattern);
  //       return control.value.length + (getSpecialCharacters === null ? 0 : getSpecialCharacters.length)
  //         > maxlength ? { maxlength: true } : null;
  //     }
  //     return null;
  //   };
  // }

  // static isValueTooLongCharacterSensitive(value: string, maxlength: number): boolean {
  //   if (value) {
  //     const getSpecialCharacters = String(value).match(ValidationPatterns.characterSensitivePattern);
  //     return value.length + (getSpecialCharacters === null ? 0 : getSpecialCharacters.length) > maxlength;
  //   }
  //   return false;
  // }

  static isOneOfBlikControlsFilled(formGroup: UntypedFormGroup) {
    const blikCode: string = formGroup.get('blikNumber').value;
    const surchargeBlikCode: string = formGroup.get('surchargeBlikNumber').value;
    if (blikCode || surchargeBlikCode) {
      formGroup.setErrors(null);
    } else {
      formGroup.setErrors({ BlikIsRequired: true });
    }
  }

  static peselControlSum(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pesel: string = String(control.value);
      if (!pesel) {
        return null;
      }
      const peselLength = pesel.length;
      if (peselLength !== 11) {
        return null;
      }
      const weights: number[] = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
      let sum = 0;
      const controlSum: number = Number.parseInt(pesel.substring(peselLength - 1), 10);
      for (let i = 0; i < peselLength - 1; i++) {
        const j: number = Number.parseInt(pesel[i], 10);
        sum += j * weights[i];
      }
      let testControlSum = 10 - (sum % 10);
      if (testControlSum === 10) {
        testControlSum = 0;
      }
      if (controlSum !== testControlSum) {
        return {
          pattern: true
        };
      }
      return null;
    };
  }

  static nipControlSum(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const nip = String(control.value);
      if (!nip) {
        return null;
      }
      const nipLength = nip.length;
      if (nipLength !== 10) {
        return null;
      }
      const weights: number[] = [6, 5, 7, 2, 3, 4, 5, 6, 7];
      let sum = 0;
      const controlSum: number = Number.parseInt(nip.substring(nipLength - 1), 10);
      for (let i = 0; i < nipLength - 1; i++) {
        const j: number = Number.parseInt(nip[i], 10);
        sum += j * weights[i];
      }
      const testControlSum = sum % 11;
      if (controlSum !== testControlSum) {
        return {
          pattern: true
        };
      }
      return null;
    };
  }

  static sanitizedPhoneNumberLength(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) {
        return null;
      }
      const phoneNumber = sanitizePhoneNumber(control.value);
      if (phoneNumber.length < min) {
        return { minlength: true };
      } else if (phoneNumber.length > max) {
        return { maxlength: true };
      } else {
        return null;
      }
    };
  }

  static isPkzChecksumValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (isNullOrUndefined(control.value)) {
        return null;
      }
      let number = control.value;

      let providedChecksum = number.charAt(0);
      let payload = number.substring(1);

      // wg orig algorytmu DXC

      let index = payload.length
      let x2 = true
      let sum = 0

      while (index) {
        const value = payload.charCodeAt(--index) - 48
        if (value < 0 || value > 9) { pattern: true };

        sum += x2 ? value * 2 : value
        x2 = !x2

      }
      sum *= 9

      let actualChecksum = sum % 10
      // 

      return providedChecksum == actualChecksum ? null : { pattern: true };
    }
  };


  private static isDateAfter(firstDate, secondDate): boolean {
    const thisDateTime = dateFromZeroYear(firstDate[0], firstDate[1], firstDate[2]).getTime();
    const otherDateTime = dateFromZeroYear(secondDate[0], secondDate[1], secondDate[2]).getTime();
    return thisDateTime > otherDateTime;
  }
}
