import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GhostButtonType } from 'src/app/shared/components/ghost-button/ghost-button-type.enum';
import { PaymentMethodType } from 'src/app/shared/models/payment/payment-dto.model';
import { ValidationPatterns } from 'src/app/shared/validation/validation-patterns.const';
import { ChoosenPaymentType } from './choosen-payment-type.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-choose-payment-method',
  templateUrl: './choose-payment-method.component.html',
  styleUrls: ['./choose-payment-method.component.scss']
})
export class ChoosePaymentMethodComponent implements OnInit {
  @Output() submitPaymentMethod: EventEmitter<ChoosenPaymentType> = new EventEmitter();
  @Input() showBackButton: boolean;
  @Input() isPeriodic: boolean;
  @Input() titleText: string;
  @Input() payButtonText: string;
  @Input() onlyBlik: boolean;
  GhostButtonType: typeof GhostButtonType = GhostButtonType;
  selectedPaymentMethod: PaymentMethodType = null;
  PaymentMethodType = PaymentMethodType;
  content: any;
  blikCodeControl: UntypedFormControl;
  env = environment;
  termsAccepted: boolean;

  constructor(
    private translate: TranslateService,
    private location: Location,
    protected router: Router
  ) {
    this.blikCodeControl = new UntypedFormControl(
      null,
      Validators.compose([
        Validators.pattern(ValidationPatterns.requiredOnlyDigit),
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6)
      ])
    );
  }

  ngOnInit(): void {
    this.translate.get('EXTERNAL_PAYMENTS.CHOOSE_METHOD').subscribe({
      next: (content) => (this.content = content)
    });

    if (this.isPeriodic) {
      this.selectedPaymentMethod = PaymentMethodType.PAYMENT_CARD;
    }
  }
  checkIfBlikCodeIsInvalid() {
    return this.blikCodeControl.invalid && this.blikCodeControl.touched;
  }

  onSubmit() {
    if (this.selectedPaymentMethod === PaymentMethodType.BLIK && this.blikCodeControl.invalid && this.env.FEATURE_TOGGLES.BLIK_LVL_ZERO) {
      return this.blikCodeControl.markAsTouched();
    }
    this.submitPaymentMethod.emit({
      paymentType: this.selectedPaymentMethod,
      blikCode: this.blikCodeControl.value
    });
  }

  getTermsAcceptance(value: boolean): void {
    this.termsAccepted = value;
  }

  backClick() {
    this.location.back();
  }
}
